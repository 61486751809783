import { Button, Checkbox, Input, message, Modal } from "antd";
import classNames from "classnames";
import moment from "moment";
import React, { useCallback, useEffect, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { CiImport } from "react-icons/ci";
import { FaCircle } from "react-icons/fa";
import { HiOutlineFolder } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectLoading } from "../../../redux/auth/selectors";
import EmpfehlungenService from "../../../service/EmpfehlungService";
import UserService from "../../../service/UserService";
import { exportToExcel } from "../../../utils/ExportAsExcel";
import { CardObj } from "./CardObj";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import { Filter, Heart, MiniShape, New, RainBowCicle } from "./Svgs";

export const customSortOrder = [
  "neu",
  "laufender-vorgang",
  "terminiert",
  "kunde",
  "kein-interesse",
  "neuer-kunde-der-promoter-wurde",
];

const getGermanPlaceholder = (field) => {
  return {
    firstname: "Vorname",
    lastname: "Nachname",
    phone: "Telefon",
  }[field];
};

const Index = ({ resultFilter, title }) => {
  const [stats, setStats] = useState({
    empfehlungen: "0",
    neueEmpfehlungen: "0",
    offeneVorgange: "0",
  });
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    phone: "",
  });
  const loading = useSelector(selectLoading);

  const handleChange = (e) => {
    const value = e.target.value;
    const name = e.target.name;
    setForm((prev) => ({ ...prev, [name]: value }));
  };
  const [list, setList] = useState([]);
  const getLists = async () => {
    try {
      const { data } = await EmpfehlungenService.list();
      setList(data);
    } catch (error) {}
  };
  const getDashboardStats = useCallback(async () => {
    try {
      const { data } = await UserService.getDashboardStats(resultFilter);

      for (let values of Object.keys(data)) {
        data[values] = data[values].split(".")[0];
      }

      setStats((prev) => ({
        ...prev,
        ...data,
      }));
    } catch (error) {}
  }, [resultFilter]);
  useEffect(() => {
    getDashboardStats();
  }, [getDashboardStats]);
  useEffect(() => {
    getLists();
  }, []);
  const [timer, setTimer] = useState();
  const [expanded, setexpanded] = useState(null);
  const [highlightedCard, setHighlightedCard] = useState("");
  const inputChanged = (e) => {
    return new Promise(() => {
      try {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
          const user = await UserService.searchEmpfehlungUberPromoter(
            e.target.value
          );

          setList(user.data);
        }, 500);

        setTimer(newTimer);
      } catch (error) {}
    });
  };
  const [filter, setFilter] = useState(false);
  const [topValue, setTopValue] = useState(400);
  const [color, setColor] = useState({
    kunde: false,
    "laufender-vorgang": false,
    neu: false,
    "kein-interesse": false,
    terminiert: false,
    "neuer-kunde-der-promoter-wurde": false,
  });
  const query = window.location.href;
  useEffect(() => {
    title("Empfehlungen");
    if (query.split("?")[1]) {
      const queryRep = query.split("?")[1].split("=");
      if (queryRep[1] === "kunde") {
        setFilter(true);
        setColor({
          kunde: true,
          "laufender-vorgang": false,
          neu: false,
          "kein-interesse": false,
          terminiert: false,
          "neuer-kunde-der-promoter-wurde": false,
        });
      } else if (queryRep[0] === "empfehlungen") {
        setHighlightedCard(queryRep[1]);
        setexpanded(0);
      }
    }
  }, [query, list]);

  // Sort and Filter Logic Empfehlungen Card
  useEffect(() => {
    const filteredAndSortedData = () => {
      const filteredData = list.filter((e) => {
        if (
          !color["kunde"] &&
          !color["laufender-vorgang"] &&
          !color["neu"] &&
          !color["kein-interesse"] &&
          !color["terminiert"] &&
          !color["neuer-kunde-der-promoter-wurde"]
        ) {
          return e;
        }
        return color[e.status];
      });

      return [...filteredData].sort((a, b) => {
        if (a._id === highlightedCard) return -1;
        if (b._id === highlightedCard) return 1;

        const calculateScore = (status) => {
          const scoreMap = {
            neu: 1,
            "laufender-vorgang": 2,
            terminiert: 3,
            kunde: 4,
            "kein-interesse": 5,
            "neuer-kunde-der-promoter-wurde": 6,
          };
          return scoreMap[status] || 0;
        };

        return calculateScore(a.status) - calculateScore(b.status);
      });
    };

    const updateListIfNecessary = (sortedData) => {
      if (JSON.stringify(sortedData) !== JSON.stringify(list)) {
        setList(sortedData);
      }
    };

    const updateExpandedIfNecessary = (sortedData) => {
      const ex = list[expanded]?._id;
      const newExpanded = sortedData.findIndex((item) => item._id === ex);
      if (ex !== newExpanded && ex != null) {
        setexpanded(newExpanded);
      }
    };

    if (list.length > 0) {
      const sortedData = filteredAndSortedData();

      updateListIfNecessary(sortedData);
      updateExpandedIfNecessary(sortedData);
    }
  }, [highlightedCard, color, list, expanded]);

  const [selected, setSelected] = useState("");
  const [newOpen, setNewOpen] = useState(false);
  const cardsCollectionRef = React.useRef();
  return (
    <div className="px-2">
      <Modal
        open={newOpen}
        title="Empfehlung nachtragen"
        onCancel={() => setNewOpen(false)}
        okText="Zustimmen & anlegen"
        onOk={async () => {
          try {
            if (loading) return;
            if (!form?.checkbox)
              return message.info(
                "Bitte bestätige, dass diese Empfehlung die Erlaubnis erteilt hat."
              );
            for (let i = 0; i < Object.keys(form).length; i++) {
              const key = Object.keys(form)[i];
              if (form[key].length === 0) {
                message.error(
                  `${key.replace(key[0], key[0].toUpperCase())} erforderlich`
                );
                return;
              }
            }
            await EmpfehlungenService.create(form).then(() => getLists());
            setNewOpen(false);
            setForm({
              firstname: "",
              lastname: "",
              phone: "",
            });
          } catch (error) {}
        }}
      >
        <div className="my-10 black-search">
          {Object.keys(form)
            .filter((key) => key !== "checkbox")
            .map((e, i) => (
              <Input
                className="my-3 px-6 font-semibold text-base "
                placeholder={getGermanPlaceholder(e)}
                name={e}
                required
                value={form[e]}
                onChange={handleChange}
              />
            ))}

          <div className="mt-5">
            Du bist gesetzlich dazu verpflichtet. Ohne Erlaubnis dürfen keine
            Daten von Dritten bei EinGuterTipp eingesetzt werden.
          </div>
          <div className=" flex items-start justify-start tertiary:gap-4 gap-1">
            <Checkbox
              value={form?.checkbox}
              onChange={(e) =>
                setForm((prev) => ({ ...prev, checkbox: e.target.checked }))
              }
            />
            <p className="text-ellipsis text-start hyphens-auto ">
              Ich bestätige, dass diese Empfehlung mir die Erlaubnis erteilt
              hat, sie hinzufügen zu dürfen.
            </p>
          </div>
        </div>
      </Modal>

      <div className="flex secondary:min-h-[400px] secondary:flex-nowrap flex-wrap min-h-full justify-between gap-10 ">
        <div className="secondary:w-[calc(100%-500px)] w-full h-full p-5 grid gap-10  overflow-hidden bg-[#fafafa] rounded-[30px] ">
          <h1 className="font-semibold sm:text-9xl text-xl">
            Empfehlungsstatistiken auf einen Blick.
          </h1>
          <div className="overflow-x-scroll flex h-[100%] py-5 items-center sm:gap-4 gap-1 ">
            <div
              className={classNames(
                "text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]",
                {
                  "border-[3px] border-black": !Object.keys(color)
                    .map((e) => (color[e] ? "no" : "yes"))
                    .includes("no"),
                }
              )}
              onClick={() => {
                setColor({
                  kunde: false,
                  "laufender-vorgang": false,
                  neu: false,
                  "kein-interesse": false,
                  terminiert: false,
                  "neuer-kunde-der-promoter-wurde": false,
                });
                setSelected("");
              }}
            >
              <span className="kunde-mark-upper text-xl justify-center  font-semibold flex items-center">
                {stats.empfehlungen}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Empfehlungen gesamt
              </p>
              <p className="flex justify-center items-center">
                <Heart />
              </p>
            </div>
            <div
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              onClick={() => {
                setColor({
                  kunde: false,
                  "laufender-vorgang": false,
                  neu: true,
                  "kein-interesse": false,
                  terminiert: false,
                  "neuer-kunde-der-promoter-wurde": false,
                });
              }}
              style={{
                background: color["neu"] ? "#bdc6be" : "transparent",
              }}
            >
              <span
                className="kunde-mark-upper text-xl w-fit m-auto font-semibold text-black"
                style={{
                  color: color["neu"] ? "#ffff" : "#000",
                }}
              >
                {stats.neueEmpfehlungen}
              </span>
              <p
                className="font-[500] text-[14px] text-[#868686]"
                style={{
                  color: color["neu"] ? "#ffff" : "#868686",
                }}
              >
                Neue Empfehlungen
              </p>
              <p className="flex justify-center items-center">
                <New />
              </p>
            </div>
            <div
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              onClick={() => {
                setColor({
                  kunde: false,
                  "laufender-vorgang": true,
                  neu: false,
                  "kein-interesse": false,
                  terminiert: false,
                  "neuer-kunde-der-promoter-wurde": false,
                });
              }}
              style={{
                background: color["laufender-vorgang"]
                  ? "#FFE600"
                  : "transparent",
              }}
            >
              <span className="red-mark-upper text-xl w-fit m-auto font-semibold ">
                {stats.offeneVorgange}
              </span>
              <p className="font-[500] text-[14px] text-[#868686]">
                Offene Vorgänge
              </p>
              <p className="flex justify-center items-center ">
                <MiniShape />
              </p>
            </div>

            {filter ? (
              <div
                // onMouseLeave={() => setFilter(!filter)}
                className="relative text-center  animate-fade cursor-pointer bg-darkslateblue text-white  flex justify-around flex-col items-center max-w-[165px] min-w-[165px] h-[195px] p-2 gap-3  rounded-[15px] border border-[#d8d8d8]"
              >
                <button
                  className="bg-white h-[20px] rounded-md w-[20px] absolute shadow-2xl flex justify-center items-center  top-1 right-[2px] text-black"
                  onClick={() =>
                    setColor({
                      kunde: false,
                      "laufender-vorgang": false,
                      neu: false,
                      "kein-interesse": false,
                      terminiert: false,
                      "neuer-kunde-der-promoter-wurde": false,
                    })
                  }
                >
                  <RxCross1 />
                </button>
                <div className="grid grid-cols-2  gap-2 text-left">
                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px] rounded-full"
                        style={{ color: color.kunde ? "#81E985" : "white" }}
                        onClick={() => {
                          setColor({ ...color, kunde: !color.kunde });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add(...["opacity-40"]);
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove(...["opacity-40"]);
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1">Kunde</div>
                  </div>
                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px] rounded-full "
                        style={{
                          color: color?.["laufender-vorgang"]
                            ? "#F9CA6B"
                            : "white",
                        }}
                        onClick={() => {
                          // if (color."laufender-vorgang" && selected === "openprocedures") {
                          //   setSelected("");
                          // }
                          setColor({
                            ...color,
                            "laufender-vorgang": !color["laufender-vorgang"],
                          });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add(...["opacity-40"]);
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove(...["opacity-40"]);
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1">
                      offener Vorgang
                    </div>
                  </div>
                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px]  rounded-full"
                        style={{ color: color.neu ? "#BDC6BE" : "white" }}
                        onClick={() => {
                          // if (color.neu && selected === "newrecommendations") {
                          //   setSelected("");
                          // }
                          setColor({ ...color, neu: !color.neu });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add(...["opacity-40"]);
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove(...["opacity-40"]);
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1">
                      neue Empfehlung
                    </div>
                  </div>
                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px] rounded-full "
                        style={{
                          color: color["kein-interesse"] ? "#ED7D6F" : "white",
                        }}
                        onClick={() => {
                          setColor({
                            ...color,
                            "kein-interesse": !color["kein-interesse"],
                          });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add("opacity-40");
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove("opacity-40");
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1">
                      Kein Interesse
                    </div>
                  </div>
                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px]  rounded-full"
                        style={{
                          color: color.terminiert ? "#68A5F6" : "white",
                        }}
                        onClick={() => {
                          setColor({ ...color, terminiert: !color.terminiert });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add(...["opacity-40"]);
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove(...["opacity-40"]);
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1 ">Terminiert</div>
                  </div>

                  <div className="flex items-center gap-[2px]">
                    <div className="w-[22px]">
                      <FaCircle
                        className=" text-[21px] rounded-full "
                        style={{
                          color: color["neuer-kunde-der-promoter-wurde"]
                            ? "#D359E7"
                            : "white",
                        }}
                        onClick={() => {
                          setColor({
                            ...color,
                            "neuer-kunde-der-promoter-wurde":
                              !color["neuer-kunde-der-promoter-wurde"],
                          });
                        }}
                        onMouseDown={(e) => {
                          e.target.classList.add(...["opacity-40"]);
                        }}
                        onMouseUp={(e) => {
                          e.target.classList.remove(...["opacity-40"]);
                        }}
                      />
                    </div>
                    <div className="text-[9px] w-[50px] ml-1 ">Promoter</div>
                  </div>
                </div>
                <p className="flex justify-center items-center text-black p-[5px]">
                  <textarea
                    name=""
                    id=""
                    className="rounded-[15px]  p-[5px] border-none outline-0 w-[145px] m-auto"
                    placeholder="Promoter Name eingeben..."
                    onChange={inputChanged}
                  />
                </p>
              </div>
            ) : (
              <div
                onClick={() => setFilter(!filter)}
                style={{ background: filter ? "#FFE600" : "transparent" }}
                className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              >
                <span className="text-xl w-fit m-auto font-semibold ">
                  FILTERN
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Promoter & Status Suche
                </p>
                <p className="flex justify-center items-center">
                  <RainBowCicle />
                </p>
              </div>
            )}
          </div>
          <div className="flex items-center flex-wrap gap-10">
            <div className="flex items-center text-[#868686] gap-4">
              <Filter />
              <p>
                Filtere direkt nach Personen, indem du die Status-Karte
                anklickst.
              </p>
            </div>
          </div>
        </div>
        <div className="secondary:min-w-[400px] secondary:max-w-[500px] secondary:w-[50%] h-[400px] w-full overflow-auto glass-circles-red-bg  justify-center   rounded-[30px] p-[10px] bg-[#fafafa] flex flex-col items-center  gap-2">
          <p className="text-[28px] font-semibold  text-white">
            Schau Dir an,
            <br />
            wie es geht!
          </p>
          <Link to={"/dashboard/tutorials"}>
            <Button type="primary" className="bg-[#001D47]">
              Zu den Tutorials
            </Button>
          </Link>
        </div>
      </div>
      <div className="">
        <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]   rounded-[30px] ">
          <div className=" p-5 mt-5">
            <h1 className="sm:text-[28px] text-xl  font-semibold">
              Alle deine Empfehlungen
            </h1>
            <div className="flex items-center gap-5 text-[#868686] flex-wrap">
              <div className="flex gap-1">
                <div>
                  <HiOutlineFolder fontSize={20} />
                </div>
                <p>
                  Finde mehr Informationen zu der jeweiligen Person, durchs
                  klicken auf die Karte
                </p>
              </div>
              <div
                className="flex items-center gap-3 cursor-pointer"
                onClick={() =>
                  exportToExcel(
                    [...list].map((e) => {
                      return {
                        Vorname: e.firstname,
                        Nachname: e.lastname,
                        Email: e.email,
                        Telefon: e.phone,
                      };
                    }),
                    "Empfehlungen"
                  )
                }
              >
                <CiImport fontSize={18} />{" "}
                <p className="select-none">Excel Export</p>
              </div>
            </div>
          </div>
          <div className="tertiary:pt-0 pt-40">
            <div
              className="relative    transition-all"
              ref={cardsCollectionRef}
              style={{
                height: cardsCollectionRef?.current?.scrollHeight,
              }}
            >
              {list.map((_, i) => {
                const props = CardObj(
                  _,
                  getLists,
                  expanded,
                  list,
                  color,
                  setTopValue,
                  setexpanded,
                  topValue,
                  setList,
                  i
                );

                return <ExpandedCard {...props} />;
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
