import { Button, Input, Modal, Tooltip, message } from "antd";
import moment from "moment/moment";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { BsArrowLeftCircle } from "react-icons/bs";
import { HiOutlineFolder } from "react-icons/hi";
import { RxCross1 } from "react-icons/rx";
import { Link, useNavigate } from "react-router-dom";
import LandingPageService from "../../../service/LandingPageService";
import PromoterService from "../../../service/PromoterService";
import PromotionsLinkService from "../../../service/PromotionsLinkService";
import UserService from "../../../service/UserService";
import { ExpandedCardVersionTwo as ExpandedCard } from "./Cards";
import {
  Filter,
  GoldEuro,
  Heart,
  Mouse,
  New,
  NewSimple,
  NotTrue,
  RainBowCicle,
  Reward,
} from "./Svgs";

import useSelection from "antd/es/table/hooks/useSelection";
import classNames from "classnames";
import { FaCircle } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selectors";
import NotificationService from "../../../service/NotificationService";
import { copyToClipboard } from "../../../utils/CopyToClipboard";

const Index = ({ resultFilter }) => {
  const { user } = useSelector(selectUser);
  const navigate = useNavigate();
  const [expanded, setexpanded] = useState(null);
  const [promoter, setPromoter] = useState(true);
  const [withOutView, setWithOutViews] = useState(false);
  const [withoutConsent, setWithOutConsent] = useState(false);
  const [topValue, setTopValue] = useState(550);
  const [list, setList] = useState([]);
  const [expandedId, setExpandedId] = useState("");
  const [promoterLinks, setPromoterLinks] = useState([]);

  const [LandingPages, setLandingPages] = useState([]);
  const [pramienNotification, setPramienNotification] = useState([]);

  const [searchtext, setSearchText] = useState("");
  const getExpandedData = async (id) => {
    const { data } = await PromotionsLinkService.list({ promoter_id: id });
    setPromoterLinks(data);
    return data;
  };
  const updateExpandedPromoter = async (id) => {
    const { data: promoterdata } = await PromoterService.detailForPromoterPage(
      id
    );
    setList((prev) =>
      [...prev].map((me) => (me._id !== id ? me : promoterdata))
    );
  };
  const getLandingPages = async () => {
    const { data } = await LandingPageService.list();

    setLandingPages(
      data.map((e) => ({
        value: e._id,
        label: e.name,
      }))
    );
  };
  const getLists = async () => {
    const { data } = await PromoterService.list();
    setList(data);

    return data;
  };
  const [stats, setStats] = useState({
    aufrufe: "0",
    aufrufeProPromoter: "0",
    eingelostePramien: "0",
    empfehlungen: "0",
    kundenOhnePromoter: "0",
    neueEmpfehlungen: "0",
    offeneVorgange: "0",
    promoter: "0",
    promoterOhneAufrufe: "0",
    promoterOhneEinwilligung: "0",
  });
  const [open, setOpen] = useState(false);
  const [highlightedCard, setHighlightedCard] = useState("");
  const cardsCollectionRef = useRef();
  const getDashboardStats = useCallback(async () => {
    try {
      const { data: notification } =
        await NotificationService.getPramienNotification();

      if (notification) setPramienNotification(notification);
      const { data } = await UserService.getDashboardStats(resultFilter);

      for (let values of Object.keys(data)) {
        data[values] = data[values].split(".")[0];
      }

      setStats((prev) => ({
        ...prev,
        ...data,
      }));
    } catch (error) {}
  }, [resultFilter]);
  useEffect(() => {
    getDashboardStats();
  }, [getDashboardStats]);
  useEffect(() => {
    getLists().then((e) => {
      if (e[e.length - 1]?._id) setExpandedId(e[e.length - 1]?._id);
    });
    getLandingPages();
  }, []);
  useEffect(() => {
    if (expandedId.length > 1) getExpandedData(expandedId);
  }, [expandedId]);
  const [filter, setFilter] = useState(false);
  const [color, setColor] = useState({
    "hell-lila": false,
    "mittel-lila": false,
    "dunkel-lila": false,
  });
  const [timer, setTimer] = useState();
  const inputChanged = (e) => {
    setSearchText(e.target.value);
    return new Promise(() => {
      try {
        clearTimeout(timer);

        const newTimer = setTimeout(async () => {
          const user = await UserService.searchUsers(e.target.value, true);

          setList(user.data.promoterUsers);
        }, 500);

        setTimer(newTimer);
      } catch (error) {}
    });
  };
  const query = window.location.href;
  useEffect(() => {
    if (query.split("?")[1]) {
      const queryRep = query.split("?")[1].split("=");
      console.log(queryRep);
      if (queryRep[0] === "promoter") {
        setHighlightedCard(queryRep[1]);
        setexpanded(0);
      }
    }
  }, [list, query]);

  return (
    <div>
      <Modal
        open={open}
        title="Neuer Promoter"
        onCancel={() => setOpen(false)}
        okText="Bestätigen"
      >
        <div className="my-10 black-search"></div>
      </Modal>{" "}
      <div className="flex secondary:min-h-[400px] h-full secondary:flex-nowrap flex-wrap justify-between gap-10 ">
        <div
          className="secondary:w-[calc(100%-500px)] w-full h-full p-5 grid gap-10  overflow-hidden bg-[#fafafa] rounded-[30px] "
          style={{ width: "auto" }}
        >
          <h1 className="text-xl font-semibold sm:text-9xl">
            Promoterstatistiken im Überblick.
          </h1>
          <div className="overflow-x-scroll flex h-[100%] p-5  items-center sm:gap-4 gap-1 ">
            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  "border-[3px] border-black text-black": !Object.keys(color)
                    .map((e) => (color[e] ? "no" : "yes"))
                    .includes("no"),
                }
              )}
              onClick={() => {
                setPromoter(!promoter);
                setWithOutConsent(false);
                setWithOutViews(false);
                setColor(() => ({
                  "hell-lila": false,
                  "mittel-lila": false,
                  "dunkel-lila": false,
                }));
              }}
            >
              <span className="flex items-center justify-center text-xl font-semibold green-mark-upper">
                {stats.promoter}
              </span>
              <p className="font-[550] text-[14px] text-[#868686]">Promoter</p>
              <p className="flex items-center justify-center">
                <Heart />
              </p>
            </div>
            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": color["mittel-lila"],
                }
              )}
              onClick={() => {
                setPromoter(false);
                setWithOutConsent(false);
                setColor(() => ({
                  "hell-lila": false,
                  "mittel-lila": true,
                  "dunkel-lila": false,
                }));
              }}
              style={{
                background: color["mittel-lila"] ? "#c858dc" : "transparent",
              }}
            >
              <span className="m-auto text-xl font-semibold green-mark-upper w-fit ">
                {stats.promoterOhneAufrufe}
              </span>
              <p className="font-[550] text-[14px] ">
                Promoter <br /> ohne Aufrufe
              </p>
              <p className="flex items-center justify-center">
                <Mouse />
              </p>
            </div>
            <div
              className={classNames(
                `text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]`,
                {
                  " text-whitner-second  ": color["hell-lila"],
                }
              )}
              onClick={() => {
                setPromoter(false);
                setWithOutViews(false);
                setColor(() => ({
                  "hell-lila": true,
                  "mittel-lila": false,
                  "dunkel-lila": false,
                }));
              }}
              style={{
                background: color["hell-lila"] ? "#df9aeb" : "transparent",
              }}
            >
              <span className="m-auto text-xl font-semibold green-mark-upper w-fit ">
                {stats.promoterOhneEinwilligung}
              </span>
              <p className="font-[550] text-[14px]">
                Promoter ohne <br /> Einwilligung
              </p>
              <p className="flex items-center justify-center">
                <NotTrue />
              </p>
            </div>
            <div
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              onClick={() => {
                navigate("/dashboard/empfehlungen?filter=kunde");
              }}
            >
              <span className="m-auto text-xl font-semibold red-mark-upper w-fit ">
                {stats.kundenOhnePromoter}
              </span>
              <p className="font-[550] text-[14px] ">
                Kunden ohne <br />
                Promoter
              </p>
              <p className="flex items-center justify-center">
                <New />
              </p>
            </div>
            <div
              className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              onClick={() => {
                navigate("/dashboard/prämien");
              }}
            >
              <span className="m-auto text-xl font-semibold green-mark-upper w-fit ">
                {stats.eingelostePramien}
              </span>
              <p className="font-[550] text-[14px] ">
                Prämien-
                <br />
                anforderungen
              </p>
              <p className="flex items-center justify-center ">
                <Reward />
              </p>
            </div>
            {filter ? (
              <div
                // onMouseLeave={() => setFilter(!filter)}
                className="text-center relative  animate-fade cursor-pointer bg-darkslateblue text-white  flex justify-around flex-col items-start max-w-[165px] min-w-[165px] h-[195px] p-2 gap-3  rounded-[15px] border border-[#d8d8d8]"
              >
                <button
                  className="bg-white h-[20px] rounded-md w-[20px] absolute shadow-2xl flex justify-center items-center  top-1 right-1 text-black"
                  onClick={() =>
                    setColor({
                      "hell-lila": false,
                      "mittel-lila": false,
                      "dunkel-lila": false,
                    })
                  }
                >
                  <RxCross1 />
                </button>
                <div className="flex items-center gap-[2px]">
                  <div className="w-[22px]">
                    <FaCircle
                      className=" text-[21px] rounded-full "
                      style={{
                        color: color["dunkel-lila"] ? "#9453D3" : "white",
                      }}
                      onClick={() => {
                        setColor({
                          ...color,
                          "dunkel-lila": !color["dunkel-lila"],
                        });
                      }}
                      onMouseDown={(e) => {
                        e.target.classList.add(...["opacity-40"]);
                      }}
                      onMouseUp={(e) => {
                        e.target.classList.remove(...["opacity-40"]);
                      }}
                    />
                  </div>
                  <div className="text-[9px]  ml-1">Promoter</div>
                </div>

                <div className="flex items-center gap-[2px]">
                  <div className="w-[22px]">
                    <FaCircle
                      className=" text-[21px] rounded-full "
                      style={{
                        color: color["mittel-lila"] ? "#C858DC" : "white",
                      }}
                      onClick={() => {
                        setColor({
                          ...color,
                          "mittel-lila": !color["mittel-lila"],
                        });
                      }}
                      onMouseDown={(e) => {
                        e.target.classList.add(...["opacity-40"]);
                      }}
                      onMouseUp={(e) => {
                        e.target.classList.remove(...["opacity-40"]);
                      }}
                    />
                  </div>
                  <div className="text-[9px]  ml-1">Promoter ohne Aufrufe</div>
                </div>

                <div className="flex items-center gap-[2px]">
                  <div className="w-[22px]">
                    <FaCircle
                      className=" text-[21px] rounded-full "
                      style={{
                        color: color["hell-lila"] ? "#DF9AEB" : "white",
                      }}
                      onClick={() => {
                        setColor({
                          ...color,
                          "hell-lila": !color["hell-lila"],
                        });
                      }}
                      onMouseDown={(e) => {
                        e.target.classList.add(...["opacity-40"]);
                      }}
                      onMouseUp={(e) => {
                        e.target.classList.remove(...["opacity-40"]);
                      }}
                    />
                  </div>
                  <div className="text-[9px]  ml-1">
                    Promoter ohne Einwilligung
                  </div>
                </div>

                <p className="flex justify-center items-center text-black p-[5px]">
                  <textarea
                    name=""
                    value={searchtext}
                    id=""
                    className="rounded-[15px]  p-[5px] border-none outline-0 w-[145px] m-auto"
                    placeholder="Promoter Name eingeben..."
                    onChange={inputChanged}
                  />
                </p>
              </div>
            ) : (
              <div
                onClick={() => setFilter(!filter)}
                style={{ background: filter ? "#FFE600" : "transparent" }}
                className="text-center grid grid-rows-3  justify-center items-center sm:max-w-[165px] sm:min-w-[165px] max-w-[110px] min-w-[110px] h-[195px] cursor-pointer p-5 gap-3  rounded-[15px] border border-[#d8d8d8]"
              >
                <span className="m-auto text-xl font-semibold w-fit ">
                  FILTERN
                </span>
                <p className="font-[500] text-[14px] text-[#868686]">
                  Promoter & Status Suche
                </p>
                <p className="flex items-center justify-center">
                  <RainBowCicle />
                </p>
              </div>
            )}
          </div>
          <div className="flex flex-wrap items-center gap-10">
            <div className="flex items-center text-[#868686] gap-4">
              <Filter />
              <p>
                Filtere direkt nach Personen, indem du die Status-Karte
                anklickst.
              </p>
            </div>
          </div>
        </div>
        <div className="secondary:w-[400px] secondary:min-w-[400px] secondary:max-w-[400px] w-full  bg-glass-circles sm:rounded-11xl rounded-xl  overflow-hidden p-3">
          <div className="font-semibold text-center text-white">
            <p className=" text-[28px]">Prämienfeed</p>
            <p className="text-[16px] "> Einlösungen & Anforderungen</p>
          </div>
          <div className="flex flex-col h-[300px] gap-3  p-2 overflow-y-auto">
            {pramienNotification.map((e, i) => (
              <Link
                to="/dashboard/prämien"
                className="cursor-pointer rounded-[20px] p-[10px] bg-[#ffffffbb] flex"
                key={i}
              >
                <div>
                  <GoldEuro />
                </div>
                <div>
                  <p className="">{e.text}</p>
                  <p className="text-[#868686]">
                    {" "}
                    {moment(e.createdAt).fromNow()}
                  </p>
                </div>
              </Link>
            ))}
          </div>
        </div>
      </div>
      <div className="border-[1px] my-10 border-[#d8d8d8] bg-[#fafafa]  min-h-[500px] rounded-[30px]">
        <div className="p-5 mt-5 ">
          <h1 className="text-[28px]  font-semibold">Alle deine Promoter</h1>
          <div className="flex items-center gap-5 text-[#868686] flex-wrap tertiary:pr-[350px]">
            <div className="flex items-center gap-2">
              <HiOutlineFolder fontSize={22} />
              <p>
                Finde mehr Informationen zu dem jeweiligen Promoter, durchs
                klicken auf die Karte
              </p>
            </div>
            <Tooltip
              title="Kopiert"
              color="green"
              trigger={"click"}
              mouseLeaveDelay={100}
              prefixCls="ant-custom-tooltip"
            >
              <Button
                onClick={() =>
                  copyToClipboard(
                    `${window.origin}/promoterregistration/${user?._id}`
                  )
                }
                type="primary"
                className="bg-[#001D47]  flex items-center gap-3 text-white whitespace-break-spaces !h-fit"
              >
                <NewSimple /> <p>Promoter Einladungslink kopieren</p>
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="pt-40 tertiary:pt-0">
          <div
            className="relative transition-all "
            ref={cardsCollectionRef}
            style={{
              height: cardsCollectionRef?.current?.scrollHeight,
            }}
          >
            {list
              .filter((e) => {
                if (searchtext.length > 0) {
                  return true;
                }
                if (
                  color["hell-lila"] === false &&
                  color["mittel-lila"] === false &&
                  color["dunkel-lila"] === false
                ) {
                  return e;
                }

                let result = false;
                if (color["dunkel-lila"]) {
                  result =
                    result || (e.allowsAutomatedMessaging && e.clicks > 1);
                }
                if (color["mittel-lila"]) {
                  result =
                    result || (e.allowsAutomatedMessaging && e.clicks <= 1);
                }
                if (color["hell-lila"]) {
                  result =
                    result || (!e.allowsAutomatedMessaging && e.clicks <= 1);
                }
                return result;
              })
              .sort((a) => {
                if (a._id === highlightedCard) return -1;
                else return 1;
              })

              .map((_, i) => {
                return (
                  <ExpandedCard
                    expanded={expanded === i}
                    user={user}
                    getExpandedData={getExpandedData}
                    expandedId={expandedId}
                    updatePromoter={async (setEditOpen, data, form) => {
                      try {
                        for (let i = 0; i < Object.keys(form).length; i++) {
                          const key = Object.keys(form)[i];
                          if (form[key].length === 0) {
                            message.error(
                              `${key.replace(
                                key[0],
                                key[0].toUpperCase()
                              )} erforderlich`
                            );
                            return;
                          } else {
                          }
                        }
                        setEditOpen(false);
                        await PromoterService.update(data._id, {
                          ...data,
                          ...form,
                        });
                        getLists();
                      } catch (error) {}
                    }}
                    data={_}
                    promoterLinks={promoterLinks}
                    setList={async ({ key, value }) => {
                      if (key === "credits") {
                        setList((prev) =>
                          [...prev].map((e) => {
                            if (e._id !== _._id) {
                              return e;
                            } else {
                              return { ...e, [key]: value };
                            }
                          })
                        );
                        return;
                      } else if (key === "notes") {
                        await PromoterService.updateNotes(_._id, {
                          notes: value,
                        }).then();
                        updateExpandedPromoter(_._id);
                        getExpandedData(_._id);
                        return;
                      } else {
                        try {
                          setList((prev) =>
                            [...prev].map((e) => {
                              if (e._id !== _._id) {
                                return e;
                              } else {
                                return { ...e, [key]: value };
                              }
                            })
                          );
                          await PromoterService.update(_._id, {
                            ..._,
                            [key]: value,
                          }).then(async () => {
                            await getLists();
                          });
                        } catch (error) {
                          console.log(error);
                        }
                      }
                    }}
                    manipulateTop={setTopValue}
                    top={topValue}
                    deleteLead={async () => {
                      try {
                        await PromoterService.deletePromoter(_._id);
                        setexpanded(null);
                        await getLists();
                        setPromoterLinks([]); //remeber to pull promoter links for next promoter
                      } catch (error) {}
                    }}
                    className={"absolute transition-all !duration-1000 "}
                    getLists={getLists}
                    options={LandingPages}
                    bg={
                      {
                        "hell-lila": "#DF9AEB",
                        "mittel-lila": "#C858DC",
                        "dunkel-lila": "#9453D3",
                      }[_?.color]
                    }
                    props={{
                      style: {
                        zIndex: i * 1,
                        top:
                          expanded === null || i <= expanded
                            ? i * 100
                            : i * 100 + topValue,
                      },
                      onClick: (callback) => {
                        if (expanded !== i) {
                          setexpanded(i);
                          setExpandedId(_._id);
                          setTopValue(550);
                          if (callback) callback();
                        }
                      },
                    }}
                  />
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
